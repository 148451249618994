import React from 'react'
import { graphql } from 'gatsby'
import { Careers } from '@templates'
import {
  prismicOfficeDetailsFields,
  prismicJobListingFields,
  prismicValuesFields,
  prismicBenefitsFields,
  prismicCaseStudyCard,
  prismicOurPeopleFields,
  prismicMailingListFields,
} from '@graphql/fragments'

const CareersPage = ({ data }) => <Careers data={data} />

CareersPage.fragments = [
  prismicOfficeDetailsFields,
  prismicJobListingFields,
  prismicValuesFields,
  prismicBenefitsFields,
  prismicOurPeopleFields,
]
export default CareersPage

export const query = graphql`
  query PrismicCareers {
    prismic {
      allContact_pages {
        edges {
          node {
            office_one {
              ...prismicOfficeDetailsFields
            }
          }
        }
      }
      allJob_listings(sortBy: date_added_DESC) {
        edges {
          node {
            ...prismicJobListingFields
          }
        }
      }
      allValuess {
        edges {
          node {
            ...prismicValuesFields
          }
        }
      }
      allBenefitss {
        edges {
          node {
            ...prismicBenefitsFields
          }
        }
      }
      allOur_peoples {
        edges {
          node {
            ...prismicOurPeopleFields
          }
        }
      }
      allCareers_pages {
        edges {
          node {
            seodescription
            seotitle
            title
            text
            bannerTitle: banner_title
            bannerText: banner_text
            quoteText: quote_text
            person {
              ... on PRISMIC_Person {
                name
                job_title
                profile_picture
              }
            }
            mailinglist {
              ... on PRISMIC_Mailing_list {
                ...prismicMailingListFields
              }
            }
          }
        }
      }
    }
    allGreenhouseJob {
      nodes {
        metadata {
          name
          value
        }
        location {
          name
        }
        departments {
          name
        }
        title
      }
    }
  }
`

CareersPage.query = query
